import FilingReports from "./1099-filing/FilingReports";
import ReportDesigner from "./exago-reports/ReportDesigner";
import ReportTree from "./exago-reports/ReportTree";
import AuditLogList from "../audit-log/views/AuditLogList.vue";

let routes = [
    {
        name: "rp:1099",
        path: "1099-filing",
        component: FilingReports,
        meta: {
            label: "1099 Filing",
            include: true,
            skipSaveOnLeave: true,
            permissionKeys: ["app1099_AppAccess"]
        }
    },
    {
        name: "rp:report-management",
        path: "report-management",
        // component: ComingSoon,
        component: ReportDesigner,
        meta: {
            label: "Reports Management",
            include: false,
            logRouteAccess: true,
            skipSaveOnLeave: true,
            permissionKeys: ["CanCreateReports"]
        }
    },
    {
        name: "rp:report-gneration",
        path: "report-generation",
        component: ReportTree,
        meta: {
            label: "Reports-Tree",
            include: false,
            logRouteAccess: true,
            skipSaveOnLeave: true,
            permissionKeys: ["appReports_AppAccess"]
        }
    },
    {
        name: "rp:audit-log",
        path: "audit-log",
        component: AuditLogList,
        meta: {
            label: "Audit Log",
            skipSaveOnLeave: true,
            featureFlag: "auditlog",
            permissionKeys: ["appSystemAuditLog_AppAccess"]
        }
    }
];

export default routes;